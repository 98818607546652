import request from '@/service/lib/request';


export async function queryActivityList() {
  return request({
    url: '/active/activities',
    method: 'get',
  });
}
export async function queryActivity(id) {
  return request({
    url: '/active/activity/' + id,
    method: 'get',
  });
}
export async function editActivity(params) {
  return request({
    url: '/active/activity',
    method: 'post',
    data: params,
  });
}

export async function queryActivityInfo(id) {
  return request({
    url: '/active/activityinfo/' + id,
    method: 'get',
  });
}
export async function queryActivityBanner(id) {
  return request({
    url: '/active/activitybanner/' + id,
    method: 'get',
  });
}
export async function queryActivityProduct(id) {
  return request({
    url: '/active/activityproduct/' + id,
    method: 'get',
  });
}
export async function queryActivitySetting(id) {
  return request({
    url: '/active/activitysetting/' + id,
    method: 'get',
  });
}
export async function queryActivityCoupon(params) {
  return request({
    url: '/active/activitycoupon',
    method: 'get',
    params
  });
}


export async function editActivityInfo(params) {
  return request({
    url: '/active/activityinfo',
    method: 'post',
    data: params,
  });
}
export async function addActivityBanner(params) {
  return request({
    url: '/active/activitybanner',
    method: 'post',
    data: params,
  });
}
export async function deleteActivityBanner(params) {
  return request({
    url: '/active/activitybanner',
    method: 'delete',
    data: params,
  });
}
export async function updateActivityBannerSort(params) {
  return request({
    url: '/active/activitybanner',
    method: 'put',
    data: params,
  });
}
export async function editActivityProduct(params) {
  return request({
    url: '/active/activityproduct',
    method: 'post',
    data: params,
  });
}
export async function editActivitySetting(params) {
  return request({
    url: '/active/activitysetting',
    method: 'post',
    data: params,
  });
}
export async function addActivityCoupon(params) {
  return request({
    url: '/active/activitycoupon',
    method: 'post',
    data: params,
  });
}
export async function addActivityScene(params) {
  return request({
    url: '/active/activityscene',
    method: 'post',
    data: params,
  });
}
export async function queryActivityScene(params) {
  return request({
    url: '/active/activityscenes',
    method: 'get',
    params,
  });
}