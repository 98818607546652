<template>
  <div class="main-conent main-conent-minheight">
    <div>
      <el-card class="SearchBox">
        <el-row>
          <el-col :span="8">
            <el-button type="primary" @click="() => ToAddPage()">新增</el-button>
          </el-col>
          <el-col :span="16" class="text-align-right">
            <el-input v-model="searchParam.title" style="width: 200px; margin-left: 16px" placeholder="请输入搜索内容">
              <template #suffix>
                <i class="el-input__icon el-icon-search cursor-pointer"></i>
              </template>
            </el-input>
            <el-button style="margin-left: 8px" @click="() => searchList()">搜索</el-button>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="box" v-loading="loading">
      <el-card class="boxcard" :body-style="{ padding: '10px 10px 0px 10px !important' }" v-for="h in list" :key="h.id">
        <template #header>
          <div class="cardHeader">
            <span>
              <el-link href="javascript:;" @click="ToEdit(h.id)" class="cardHeaderLink">
                {{h.title}}
              </el-link>
            </span>
            <span>
              活动编号：{{h.id}}
            </span>
          </div>
        </template>
        <div class="boxBody">
          <div class="boxContent">
            <div class="tags">
              <el-tag v-for="tag in h.products" :key="tag.Id">{{ tag.title }} +{{ tag.count }}</el-tag>
            </div>
            <div class="lineContent">
              <span>销售价格：</span><span>{{ h.totalPrice }}元</span>
            </div>
            <div class="lineContent">
              <span>活动截止日期：</span><span>{{ h.endDate }}</span>
            </div>
            <el-divider style="margin: 10px 0"></el-divider>
            <div class="lineDescript">
              <span>创建于：</span><span>2021-10-01</span>
            </div>
          </div>
          <div class="boxFoot">
            <el-row :gutter="20" style="margin-top:10px;">
              <el-button class="cardEditButton" round size="medium" type="primary" @click="ToEditInfoPage(h.id)">活动信息</el-button>
              <el-button class="cardEditButton" round size="medium" type="primary" @click="ToEditBannerPage(h.id)">广告图</el-button>
              <el-button class="cardEditButton" round size="medium" type="primary" @click="ToEditProductPage(h.id)">酒水设置</el-button>
              <el-button class="cardEditButton" round size="medium" type="primary" @click="ToEditSettingPage(h.id)">微信配置</el-button>
              <el-button class="cardEditButton" round size="medium" type="primary" @click="ToEditCouponPage(h.id)">优惠券配置</el-button>
              <el-button class="cardEditButton" round size="medium" type="warning">统计</el-button>
              <el-button class="cardEditButton" round size="medium" type="primary" @click="ToScenePage(h.id)">渠道列表</el-button>
            </el-row>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { queryActivityList } from '@/service/activity.js';
export default {
  data () {
    return {
      loading: false,
      searchParam: {
        title: ''
      },
      list: []

    };
  },
  created () {
    this.DataBinding();
  },
  methods: {
    //数据加载
    DataBinding () {
      this.loading = true;
      queryActivityList().then(res => {
        this.list = res.data;
        this.loading = false;
      }).catch(ex => {
        this.loading = false;
      })
    },
    //编辑基础信息
    ToEditInfoPage (activeId) {
      this.$router.push({ name: 'activity_editinfo', query: { activeId: activeId } });
    },
    //编辑广告信息
    ToEditBannerPage (activeId) {
      this.$router.push({ name: 'activity_editbanner', query: { activeId: activeId } });
    },
    //编辑产品信息
    ToEditProductPage (activeId) {
      this.$router.push({ name: 'activity_editproduct', query: { activeId: activeId } });
    },
    //编辑配置信息
    ToEditSettingPage (activeId) {
      this.$router.push({ name: 'activity_editsetting', query: { activeId: activeId } });
    },
    //编辑优惠券
    ToEditCouponPage (activeId) {
      this.$router.push({ name: 'activity_editcoupon', query: { activeId: activeId } });
    },
    //渠道列表
    ToScenePage(activeId){
      this.$router.push({ name: 'activity_scenelist', query: { activeId: activeId } });
    },
    //编辑
    ToAddPage () {
      this.$router.push({ name: 'activity_editinfo' });
    }
  },
};
</script>



<style scoped lang="scss">
.SearchBox {
  margin-bottom: 10px;
}
.box {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  .boxcard {
    width: 500px;
    min-width: 300px;
    padding: 10px;
    margin: 0 0 10px 10px;
    .cardHeader {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .cardHeaderLink {
        font-size: 20px;
        font-family: "黑体";
      }
    }
    .boxBody {
      font-family: 宋体;
      // height: 150px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      .boxContent {
        .tags {
          padding: 0 0 0 0;
          .el-tag {
            margin: 0px 5px 7px 5px;
            font-size: 15px;
          }
        }
        .lineContent {
          margin-bottom: 5px;
          font-size: 15px;
        }
        .lineContent :first-child {
          font-weight: 500;
        }
        .lineContent :not(:first-child) {
          font-weight: 300;
        }
        .lineDescript {
          font-size: 13px;
          color: lightgray;
          margin-bottom: 5px;
        }
      }
      .boxFoot {
        padding-bottom: 10px;
        .cardEditButton {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>